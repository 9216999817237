<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.title.userReport')">
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-2 my-1">
          {{ $t("message.year") }} :
          <br />
          <b-form-select v-model="timeSel" :options="language== 'th'? timeOptTH: timeOptEN" v-on:change="selectTime" />
        </div>

        <div class="col-sm-6 col-md-5 col-lg-4">
          {{ $t('message.selectMonth') }} :
          <br>
          <vue-monthly-picker v-model="selectedMonth" :monthLabels="language == 'th' ? monthsTH : monthsEN " dateFormat="MM/YYYY"></vue-monthly-picker>
        </div>
        <div class="col-sm-12 col-md-2 col-lg-4">
          <br>
          <b-button v-on:click="getUserReport()" variant="primary">
            <i class="fa fa-search"></i>&nbsp;{{ $t('message.search') }}
          </b-button>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table-grid">
          <thead style="text-align-last: center">
            <tr>
              <th rowspan="2">{{ $t('message.machineIdFarm') }}</th>
              <th rowspan="2">{{ $t('message.type') }}</th>
              <th colspan="2">{{ $t('message.wash') }}</th>
              <th colspan="2">{{ $t('message.dry') }}</th>
              <th colspan="2">{{ $t('message.countAll') }}</th>
            </tr>
            <tr>
              <th width="12.5%">{{ $t('message.amount') }}</th>
              <th width="12.5%">{{ $t('message.percentChange') }}</th>
              <th width="12.5%">{{ $t('message.amount') }}</th>
              <th width="12.5%">{{ $t('message.percentChange') }}</th>
              <th width="12.5%">{{ $t('message.amount') }}</th>
              <th width="12.5%">{{ $t('message.percentChange') }}</th>
            </tr>
          </thead>
          <tbody style="text-align-last: center">
            <template v-for="items in rowData ">
              <tr>
                <td rowspan="3">{{items.machineId}}</td>
                <td>{{ $t('message.allUser') }}</td>
                <td>{{items.sumCMCountWasher}}</td>
                <template v-if="Math.sign(items.percentChangeAllWasher) === 1">
                  <td
                    style="color: green"
                  >+{{items.percentChangeAllWasher ? items.percentChangeAllWasher.toFixed(2) : 0 }}%</td>
                </template>
                <template v-else>
                  <td
                    style="color: red"
                  >{{items.percentChangeAllWasher ? items.percentChangeAllWasher.toFixed(2) : 0 }}%</td>
                </template>
                <td>{{items.sumCMCountDryer}}</td>
                <template v-if="Math.sign(items.percentChangeAllDryer) === 1">
                  <td
                    style="color: green"
                  >+{{items.percentChangeAllDryer ? items.percentChangeAllDryer.toFixed(2) : 0 }}%</td>
                </template>
                <template v-else>
                  <td
                    style="color: red"
                  >{{items.percentChangeAllDryer ? items.percentChangeAllDryer.toFixed(2) : 0 }}%</td>
                </template>
                <td>{{items.sumCMCountDryer + items.sumCMCountWasher}}</td>
                <template v-if="Math.sign(items.percentChangeAll) === 1">
                  <td
                    style="color: green"
                  >+{{items.percentChangeAll ? items.percentChangeAll.toFixed(2) : 0 }}%</td>
                </template>
                <template v-else>
                  <td
                    style="color: red"
                  >{{items.percentChangeAll ? items.percentChangeAll.toFixed(2) : 0 }}%</td>
                </template>
              </tr>
              <tr>
                <td>{{ $t('message.newUser') }}</td>
                <td>{{items.newCMCountWasher}}</td>
                <template v-if="Math.sign(items.percentChaneNewWasher) === 1">
                  <td
                    style="color: green"
                  >+{{items.percentChaneNewWasher ? items.percentChaneNewWasher.toFixed(2) : 0 }}%</td>
                </template>
                <template v-else>
                  <td
                    style="color: red"
                  >{{items.percentChaneNewWasher ? items.percentChaneNewWasher.toFixed(2) : 0 }}%</td>
                </template>
                <td>{{items.newCMCountDryer}}</td>
                <template v-if="Math.sign(items.percentChangeNewDryer) === 1">
                  <td
                    style="color: green"
                  >+{{items.percentChangeNewDryer ? items.percentChangeNewDryer.toFixed(2) : 0 }}%</td>
                </template>
                <template v-else>
                  <td
                    style="color: red"
                  >{{items.percentChangeNewDryer ? items.percentChangeNewDryer.toFixed(2) : 0 }}%</td>
                </template>
                <td>{{items.newCMCountDryer + items.newCMCountWasher}}</td>
                <template v-if="Math.sign(items.percentChangeNew) === 1">
                  <td
                    style="color: green"
                  >+{{items.percentChangeNew ? items.percentChangeNew.toFixed(2) : 0 }}%</td>
                </template>
                <template v-else>
                  <td
                    style="color: red"
                  >{{items.percentChangeNew ? items.percentChangeNew.toFixed(2) : 0 }}%</td>
                </template>
              </tr>
              <tr>
                <td>{{ $t('message.oldUser') }}</td>
                <td>{{items.oldCMCountWasher}}</td>
                <template v-if="Math.sign(items.percentChaneOldWasher) === 1">
                  <td
                    style="color: green"
                  >+{{items.percentChaneOldWasher ? items.percentChaneOldWasher.toFixed(2) : 0 }}%</td>
                </template>
                <template v-else>
                  <td
                    style="color: red"
                  >{{items.percentChaneOldWasher ? items.percentChaneOldWasher.toFixed(2) : 0 }}%</td>
                </template>
                <td>{{items.oldCMCountDryer}}</td>
                <template v-if="Math.sign(items.percentChangeOldDryer) === 1">
                  <td
                    style="color: green"
                  >+{{items.percentChangeOldDryer ? items.percentChangeOldDryer.toFixed(2) : 0 }}%</td>
                </template>
                <template v-else>
                  <td
                    style="color: red"
                  >{{items.percentChangeOldDryer ? items.percentChangeOldDryer.toFixed(2) : 0 }}%</td>
                </template>
                <td>{{items.oldCMCountDryer + items.oldCMCountWasher}}</td>
                <template v-if="Math.sign(items.percentChangeOld) === 1">
                  <td
                    style="color: green"
                  >+{{items.percentChangeOld ? items.percentChangeOld.toFixed(2) : 0 }}%</td>
                </template>
                <template v-else>
                  <td
                    style="color: red"
                  >{{items.percentChangeOld ? items.percentChangeOld.toFixed(2) : 0 }}%</td>
                </template>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </b-card>
  </div>
</template>

<script>
import webServices from "../../script";
import VueMonthlyPicker from "vue-monthly-picker";
import moment from "moment";
export default {
  name: "Reports",
  components: {
    VueMonthlyPicker
  },
  data() {
    return {
      api: {},
      timeSel: 0,
      timeOptTH: [
        { value: 0, text: '2022 ถึง ปีปัจจุบัน' },
        { value: 1, text: '<2022'}
      ],
      timeOptEN: [
        { value: 0, text: '2022 To This Year' },
        { value: 1, text: '<2022'}
      ],
      searchParams: {},
      selectedMonth: moment(new Date()),
      rowData: [],
      language: window.localStorage.getItem("language"),
      monthsTH: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
      monthsEN: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    };
  },
  methods: {
    selectTime(value) {
      if (value > 0) {
        this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
      } else {
        this.api.defaults.baseURL = this.axios.defaults.baseURL
      }
    },

    changeLanguage(index) {
      if (index === 0) {
        this.$i18n.locale = "th";
        this.flag = `flag-icon flag-icon-th`
        window.localStorage.setItem('language', 'th')

      } else if (index === 1) {
        this.$i18n.locale = "gb";
        this.flag = `flag-icon flag-icon-gb`
        window.localStorage.setItem('language', 'gb')
      }
      this.$store.state.nav = this.$t("message.nav")
    },

    getUserReport() {
      const searchMonth = new Date(this.selectedMonth);
      this.searchParams.month = `${searchMonth.getMonth() +
        1}-${searchMonth.getFullYear()}`;
      this.searchParams.lastMonth = `${searchMonth.getMonth()}-${searchMonth.getFullYear()}`;

      var localStorageUser =  JSON.parse(window.localStorage.getItem("users") )
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchParams.userName = localStorageUser.userName
      this.searchParams.userRole = localStorageUser.role
      this.searchParams.userType = localStorageUser.type
      this.searchParams.userConfiglanguage = localStorageUser.config.language
      this.searchParams.datetimeRequest = datetimeRequest

      webServices
        .getUserReport(this.searchParams)
        .then(res => {
          if (res.data.success) {
            res.data.currentMonth.forEach(valueCurrent => {
              res.data.lastMonth.forEach(valueLast => {
                if (valueCurrent.machineId === valueLast.machineId) {
                  valueCurrent.percentChangeAllWasher =
                    ((valueCurrent.sumCMCountWasher -
                      valueLast.sumCMCountWasher) *
                      100) /
                    valueCurrent.sumCMCountWasher;
                  valueCurrent.percentChangeAllDryer =
                    ((valueCurrent.sumCMCountDryer -
                      valueLast.sumCMCountDryer) *
                      100) /
                    valueCurrent.sumCMCountDryer;
                  valueCurrent.percentChangeAll =
                    ((valueCurrent.sumCMCountDryer +
                      valueCurrent.sumCMCountWasher -
                      valueLast.sumCMCountDryer -
                      valueLast.sumCMCountWasher) *
                      100) /
                    (valueCurrent.sumCMCountDryer +
                      valueCurrent.sumCMCountWasher);

                  valueCurrent.percentChaneNewWasher =
                    ((valueCurrent.newCMCountWasher -
                      valueLast.newCMCountWasher) *
                      100) /
                    valueCurrent.newCMCountWasher;
                  valueCurrent.percentChangeNewDryer =
                    ((valueCurrent.newCMCountDryer -
                      valueLast.newCMCountDryer) *
                      100) /
                    valueCurrent.newCMCountDryer;
                  valueCurrent.percentChangeNew =
                    ((valueCurrent.newCMCountWasher +
                      valueCurrent.newCMCountDryer -
                      valueLast.newCMCountWasher -
                      valueLast.newCMCountDryer) *
                      100) /
                    (valueCurrent.newCMCountWasher +
                      valueCurrent.newCMCountDryer);

                  valueCurrent.percentChaneOldWasher =
                    ((valueCurrent.oldCMCountWasher -
                      valueLast.oldCMCountWasher) *
                      100) /
                    valueCurrent.oldCMCountWasher;
                  valueCurrent.percentChangeOldDryer =
                    ((valueCurrent.oldCMCountDryer -
                      valueLast.oldCMCountDryer) *
                      100) /
                    valueCurrent.oldCMCountDryer;
                  valueCurrent.percentChangeOld =
                    ((valueCurrent.oldCMCountWasher +
                      valueCurrent.oldCMCountDryer -
                      valueLast.oldCMCountWasher -
                      valueLast.oldCMCountDryer) *
                      100) /
                    (valueCurrent.oldCMCountWasher +
                      valueCurrent.oldCMCountDryer);
                }
              });
            });
            this.rowData = res.data.currentMonth;
          }
        })
        .catch(err => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          } else {
            const error = webServices.showError(res.data);
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(error)
            });
          }
          console.log(err);
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          };
          this.$toast.error({
            title: "ERROR",
            message: JSON.stringify(errorText)
          });
        });
    }
  },
  mounted() {
    this.getUserReport();
  }
};
</script>